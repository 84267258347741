<template>
  <portal to="history-event-modal">
    <div
      v-if="event"
      class="wrap-history-event-modal"
    >
      <div class="header">
        <div class="title-page">
          <div class="title">{{ eventTitle }}</div>
          <el-tooltip popper-class="el-tooltip" effect="dark" :visible-arrow="false" v-delTabIndex :content="$t('history_event_previous_tooltip')/*上一筆(左鍵)*/" placement="bottom">
            <div class="btn" :class="{ disabled: isStopPrev }" @click="setPrevEvent">
              <img src="@/assets/icons/arrow-prev.svg" alt="">
            </div>
          </el-tooltip>
          <el-tooltip popper-class="el-tooltip" effect="dark" :visible-arrow="false" v-delTabIndex :content="$t('history_event_next_tooltip')/*下一筆(右鍵)*/" placement="bottom">
            <div class="btn" :class="{ disabled: isStopNext }" @click="setNextEvent">
              <img src="@/assets/icons/arrow-next.svg" alt="">
            </div>
          </el-tooltip>
          <div class="event-index">{{ eventIndex }}</div>
        </div>
        <div class="close">
          <TooltipElement
            :content="$t('history_event_back_tooltip') /*關閉(Esc)*/"
            placement="top"
            alwaysShow
          >
            <div class="btn" @click="goBack">
              <img src="@/assets/icons/clear.svg" alt="">
            </div>
          </TooltipElement>
        </div>
    </div>
    <div class="control">
      <TooltipElement
        :content="$t('history_event_screen_capture') /*擷取畫面*/"
        placement="bottom"
        alwaysShow
      >
        <div class="btn" @click="handleScreenCapture">
          <img src="@/assets/icons/print-screen.svg" alt="" />
        </div>
      </TooltipElement>
      <TooltipElement
        v-if="hasVideo"
        :content="$t('video_download' /*下載影片*/)"
        placement="bottom"
        alwaysShow
      >
        <div v-if="searchLoading" class="btn">
          <RingLoading
            :width="32"
            :height="32"
            :ringWidth="7"
            background="transparent"
          />
        </div>
        <div v-else class="btn" :class="{ disabled: total === 0 }" @click="handleShowVideoPanel">
          <img src="@/assets/icons/download.svg" alt="" />
        </div>
      </TooltipElement>
      <div v-if="hasVideo" class="divider"></div>
      <div v-if="hasVideo" class="switch-group">
        <TooltipElement
          :content="$t('history_show_snapshot' /*顯示圖片*/)"
          placement="bottom"
          alwaysShow
        >
          <div 
            class="switch pic" 
            :class="{ active: !isBackPlay }" 
            @click="updateIsBackPlay(false)">
            <img src="@/assets/icons/picture.svg" alt="" />
          </div>
        </TooltipElement>
        <TooltipElement
          :content="$t('history_show_video' /*顯示影片*/)"
          placement="bottom"
          alwaysShow
        >
          <div 
            class="switch video" 
            :class="{ active: isBackPlay, disabled: total === 0 }" 
            @click="handleVideoToggle">
            <img src="@/assets/icons/video-play.svg" alt="" />
          </div>
        </TooltipElement>
      </div>
      
    </div>
      <div class="container-history-modal" :class="{ 'print-screen': isPrintScreen }">
        <EventDetailFr v-if="historyEventModalType === 'fr'" :event="event" />
        <EventDetail v-else :event="event" :type="eventType" />
        <div class="event-action">
          <div class="top">
            <div v-if="showMapLock" class="action lock" @click="handleMapLock">
              <img
                v-if="isMapDraggable"
                src="@/assets/icons/WebMapCenter.svg"
                alt=""
              />
              <img v-else src="@/assets/icons/WebMapCenter_Locked.svg" alt="" />
            </div>
          </div>
          <div class="bottom">
            <div class="action" @click="handleEngineerMode">
              <img
                :class="{ 'engineer-disabled': !isEngineerMode }"
                src="@/assets/icons/Engineer.svg"
                alt=""
              />
            </div>
            <EventMisjudgment v-if="showMisjudgement" :event="event" :type="eventType" />
          </div>
        </div>
      </div>
      <div
        v-if="isPrintScreen"
        :id="printScreenId"
        :key="printScreenId"
        class="history-screen-shot"
      >
        <div class="title">{{ eventTitle }}</div>
        <template v-if="historyEventModalType === 'fr'">
          <EventDetailFr
            :key="printScreenId + '-detail'"
            :event="event"
            :isPrintScreen="isPrintScreen"
          />
        </template>
        <EventDetail
          v-else
          :key="printScreenId + '-detail'"
          :event="event"
          :type="eventType"
          :isPrintScreen="isPrintScreen"
        />
      </div>

      <div v-if="isPrintScreen" class="is-capturing">
        <RingLoading />
      </div>

      <VideoDownload
        v-if="isShowEventVideoPanel"
        :queryByTime="false"
        :canDelete="false"
        @close="updateIsShowEventVideoPanel(false)"
      />
      <VideoDownload
        v-if="isShowVideoDownloadPanel"
        :queryByTime="true"
        :canDelete="false"
        @close="updateIsShowVideoDownloadPanel(false)"
      />
    </div>
  </portal>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import html2canvas from 'html2canvas'
import { getEventFilename, getEventTitle } from '@/utils/lib.js'

export default {
  name: 'HistoryEventModal',
  components: {
    VideoDownload: () => import('@/components/Base/VideoDownload.vue'),
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
    EventMisjudgment: () => import('@/components/Base/EventMisjudgment.vue'),
    EventDetail: () => import('@/components/Base/EventDetail.vue'),
    EventDetailFr: () => import('@/components/Base/EventDetailFr.vue'),
    RingLoading: () => import('@/components/Base/RingLoading.vue')
  },
  data() {
    return {
      isEngineerMode: false,
      isPrintScreen: false,
      eventSettings: {
        lpr: {
          eventIndex: 'historyEventIndex',
          eventList: 'historyEventList',
          pageSize: 'historyPageSize',
          currentPage: 'historyCurrentPage',
          searchMethod: this.searchEvents,
          updateEventIndexMethod: this.updateHistoryEventIndex,
          updateFocusIndexMethod: this.updateFocusEventIndex
        },
        fr: {
          eventIndex: 'frCurrentIndex',
          eventList: 'frEventList',
          pageSize: 'frPageSize',
          currentPage: 'frCurrentPage',
          searchMethod: this.searchFrEvents,
          updateEventIndexMethod: this.updateFrCurrentIndex,
          updateFocusIndexMethod: this.updateFrFocusIndex
        },
        or: {
          eventIndex: 'orCurrentIndex',
          eventList: 'orEventList',
          pageSize: 'orPageSize',
          currentPage: 'orCurrentPage',
          searchMethod: this.searchOrEvents,
          updateEventIndexMethod: this.updateOrCurrentIndex,
          updateFocusIndexMethod: this.updateOrFocusIndex
        },
        urgent: {
          eventIndex: 'urgCurrentIndex',
          eventList: 'urgEventList',
          pageSize: 'urgPageSize',
          currentPage: 'urgCurrentPage',
          searchMethod: this.searchUrgentEvents,
          updateEventIndexMethod: this.updateUrgCurrentIndex,
          updateFocusIndexMethod: this.updateUrgFocusIndex
        },
        video: {
          eventIndex: 'videoIndex',
          eventList: 'historyVideoList',
          pageSize: 'pageSize',
          currentPage: 'videoCurrentPage',
          searchMethod: this.searchVideoList,
          updateEventIndexMethod: this.updateVideoIndex,
          updateFocusIndexMethod: this.updateVideoFocusIndex
        }
      }
    }
  },
  computed: {
    ...mapState(['codeBooks', 'tagList']),
    ...mapState('history', [
      'searchMode',
      'historyEventList',
      'historyCurrentPage',
      'historyEventIndex',
      'historyEventModalType'
    ]),
    ...mapState('historyFr', [
      'frEventList',
      'frCurrentPage',
      'frCurrentIndex'
    ]),
    ...mapState('historyOr', [
      'orEventList',
      'orCurrentPage',
      'orCurrentIndex'
    ]),
    ...mapState('historyUrgent', [
      'urgMode',
      'urgEventList',
      'urgPageSize',
      'urgCurrentPage',
      'urgCurrentIndex'
    ]),
    ...mapState('historyVideo', [
      'videoCurrentPage',
      'videoIndex',
      'historyVideoList',
      'pageSize',
      'videoTotal'
    ]),
    ...mapState('video', [
      'total',
      'videoList',
      'searchLoading',
      'isShowEventVideoPanel',
      'isShowVideoDownloadPanel',
      'isBackPlay',
      'isMapDraggable'
    ]),
    ...mapGetters('history', ['historyModalEvent', 'totalEventNo']),
    ...mapGetters('historyFr', ['frModalEvent', 'totalFrEventNo']),
    ...mapGetters('historyOr', ['orModalEvent', 'totalOrEventNo']),
    ...mapGetters('historyUrgent', ['urgModalEvent', 'totalUrgEventNo']),
    ...mapGetters('historyVideo', ['modalVideo', 'totalVideoNo']),
    // ...mapGetters(['pageCount']),
    event() {
      const eventMap = {
        lpr: this.historyModalEvent,
        fr: this.frModalEvent,
        or: this.orModalEvent,
        urgent: this.urgModalEvent,
        video: this.modalVideo
      }
      return eventMap[this.historyEventModalType]
    },
    eventIndex() {
      const indexMap = {
        lpr: this.totalEventNo,
        fr: this.totalFrEventNo,
        or: this.totalOrEventNo,
        urgent: this.totalUrgEventNo,
        video: this.totalVideoNo
      }
      return indexMap[this.historyEventModalType]
    },
    eventType() {
      // lpr, chased, sos, fr, video, or
      if (this.historyEventModalType === 'fr') return 'fr'
      if (this.historyEventModalType === 'or') return 'or'
      if (this.historyEventModalType === 'video') return 'video'
      if (this.historyEventModalType === 'urgent') return this.urgMode
      return 'lpr'
    },
    eventTitle() {
      return getEventTitle(this.eventType)
    },
    hasVideo() {
      return this.total > 0
      // return (
      //   this.historyEventModalType === 'urgent' ||
      //   this.historyEventModalType === 'video' ||
      //   this.historyEventModalType === 'or'
      //   (this.historyEventModalType === 'lpr' &&
      //     this.historyModalEvent.chasingStartTime)
      // )
    },
    isStopPrev() {
      const settings = this.eventSettings[this.historyEventModalType]
      if (!settings) return true // 如果事件不是 'lpr', 'fr', 或 'urgnet', 'video'，回傳 true
      return this[settings.eventIndex] === 0 && this[settings.currentPage] === 1
    },
    isStopNext() {
      const settings = this.eventSettings[this.historyEventModalType]
      if (!settings) return true // 如果事件不是 'lpr', 'fr', 或 'urgnet', 'video'，回傳 true
      if (this.historyEventModalType === 'video') {
        return this.totalVideoNo === this.videoTotal
      }
      return this[settings.eventIndex] === this[settings.eventList].length - 1
    },
    showMapLock() {
      return (
        this.historyEventModalType === 'lpr' ||
        this.historyEventModalType === 'or' ||
        this.historyEventModalType === 'urgent' ||
        this.historyEventModalType === 'video'
      )
    },
    showMisjudgement() {
      return this.historyEventModalType === 'lpr' || 
        this.historyEventModalType === 'fr' ||
        this.urgMode === 'chased'
    },
    printScreenId() {
      return `history-screen-shot-${this.eventType}-${this.event.id}`
    }
  },
  watch: {
    event() {
      if (!this.event) this.updateHistoryEventModalShow(false)
    },
    historyCurrentPage(newVal, oldVal) {
      let eventIdx = newVal < oldVal ? this.historyEventList.length - 1 : 0
      this.updateHistoryEventIndex(eventIdx)
      this.updateFocusEventIndex(eventIdx)
    },
    frCurrentPage(newVal, oldVal) {
      let eventIdx = newVal < oldVal ? this.frEventList.length - 1 : 0
      this.updateFrCurrentIndex(eventIdx)
      this.updateFrFocusIndex(eventIdx)
    }
  },
  created() {
    window.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp)
  },
  methods: {
    ...mapMutations('history', [
      'updateHistoryEventIndex',
      'updateFocusEventIndex',
      'updateHistoryEventModalShow'
    ]),
    ...mapMutations('historyFr', [
      'updateFrCurrentIndex',
      'updateFrFocusIndex'
    ]),
    ...mapMutations('historyOr', [
      'updateOrCurrentIndex',
      'updateOrFocusIndex'
    ]),
    ...mapMutations('historyUrgent', [
      'updateUrgCurrentIndex',
      'updateUrgFocusIndex'
    ]),
    ...mapMutations('historyVideo', [
      'updateVideoIndex',
      'updateVideoFocusIndex'
    ]),
    ...mapMutations('video', [
      'updateIsShowEventVideoPanel',
      'updateIsShowVideoDownloadPanel',
      'updateIsBackPlay',
      'updateIsMapDraggable'
    ]),
    ...mapActions('history', ['searchEvents']),
    ...mapActions('historyFr', ['searchFrEvents']),
    ...mapActions('historyOr', ['searchOrEvents']),
    ...mapActions('historyUrgent', ['searchUrgentEvents']),
    ...mapActions('historyVideo', ['searchVideoList']),
    goBack() {
      this.updateHistoryEventModalShow(false) // 關閉跳窗
    },
    setPrevEvent() {
      const settings = this.eventSettings[this.historyEventModalType]
      if (this.isStopPrev) return
      if (this[settings.eventIndex] === 0 && this[settings.currentPage] > 1) {
        settings.searchMethod({ page: this[settings.currentPage] - 1 })
      } else {
        let eventIdx = this[settings.eventIndex] - 1
        settings.updateEventIndexMethod(eventIdx)
        settings.updateFocusIndexMethod(eventIdx)
      }
    },
    setNextEvent() {
      const settings = this.eventSettings[this.historyEventModalType]
      if (this[settings.eventIndex] === this[settings.eventList].length - 1) {
        if (this[settings.eventList].length === this[settings.pageSize]) {
          settings.searchMethod({ page: this[settings.currentPage] + 1 })
        }
      } else {
        let eventIdx = this[settings.eventIndex] + 1
        settings.updateEventIndexMethod(eventIdx)
        settings.updateFocusIndexMethod(eventIdx)
      }
    },
    onKeyUp(e) {
      e.preventDefault()
      if (e.keyCode === 27) this.goBack() // Esc
      if (e.keyCode === 37) this.setPrevEvent() // Left
      if (e.keyCode === 39) this.setNextEvent() // Right
    },
    handleVideoToggle() {
      if (this.total === 0) return
      this.updateIsBackPlay(true)
    },
    handleShowVideoPanel() {
      if (this.total === 0) return
      this.updateIsShowEventVideoPanel(true)
    },
    /* 取得畫面截圖放於剪貼簿 */
    getScreenShot() {
      let src = document.getElementById(this.printScreenId)
      html2canvas(src, { useCORS: true, allowTaint: true }).then(function (
        canvas
      ) {
        canvas.toBlob(function (blob) {
          navigator.clipboard
            .write([
              new window.ClipboardItem(
                Object.defineProperty({}, blob.type, {
                  value: blob,
                  enumerable: true
                })
              )
            ])
            .then(function () {})
        })
      })
    },
    handleScreenCapture() {
      this.isPrintScreen = true
      setTimeout(() => {
        this.screenCapture()
      }, 2000)
    },
    screenCapture() {
      this.$nextTick(() => {
        let src = document.getElementById(this.printScreenId)
        html2canvas(src, { 
          useCORS: true, 
          allowTaint: true, 
          backgroundColor: '#282942',
          scrollX: 0,
          scrollY: 0,
          windowWidth: src.scrollWidth,
          windowHeight: src.scrollHeight,
        })
        .then((canvas) => {
          /**
           * 擷取畫面有時會發生CSS失效導致截圖跑版
           * 這邊檢查截圖大小是否與畫面大小相差太大，若是則提示使用者進行reload，再進行截圖
           * TODO: 未來可考慮針對截圖頁面進行重構(頁面單純顯示圖片與資料，移除不必要的元件與功能)
           */
          if (this.checkScreenShotSize(src.scrollWidth, src.scrollHeight, canvas.width, canvas.height) === false) {
            this.$notify({ 
              title: this.$t('search_hint'), 
              message: this.$t('history_event_screen_capture_info'), 
              type: 'warning' 
            })
            this.isPrintScreen = false
            return
          }
          canvas.toBlob((blob) => {
            const link = document.createElement('a')
            const imgURL = URL.createObjectURL(blob)
            link.download = getEventFilename(this.event)
            link.href = imgURL
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(imgURL)
            this.isPrintScreen = false
          })
        })
        .catch((err) => {
          this.isPrintScreen = false
          console.log(err)
        })
      })
    },
    checkScreenShotSize(targetW, targetH, canvasW, canvasH) {
      const diffW = Math.abs(canvasW - targetW) / targetW
      const diffH = Math.abs(canvasH - targetH) / targetH
      // mac 螢幕截圖 canvasW 會比 targetW 大1倍(diffW = diffH = 1)
      // 正常狀況，diffW = diffH = 0, 但有時會有誤差，所以設定誤差範圍
      if (diffW === diffH || (diffW < 0.002 && diffH < 0.002)) {
        return true
      }
      return false
    },
    handleDownloadPanel() {
      this.updateIsQueryByTime(false) // 由大卡片上方按鈕開啟時，關閉時間搜尋
      this.updateIsShowVideoDownloadPanel(true)
    },
    handleMapLock() {
      this.updateIsMapDraggable(!this.isMapDraggable)
    },
    handleEngineerMode() {
      this.isEngineerMode = !this.isEngineerMode
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrap-history-event-modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 8px 8px 0 20px;
  box-sizing: border-box;
  background: #282942;
  z-index: 10;
  font-size: 24px;
  line-height: 24px;
  font-weight: 300;
  color: #ffffff;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #ffffff33;
  }
  &.disabled {
    cursor: not-allowed;
    @include filter_FFF_20;
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  .title-page {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #ffffff;
}

.control {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 12px;

  .divider {
    width: 1px;
    height: 36px;
    background: #ffffff80;
  }

  .switch-group {
    display: flex;
    align-items: center;
  }

  .switch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 36px;
    border-radius: 8px;
    background: #ffffff33;
    cursor: pointer;
    &:hover {
      background: #ffffff50;
    }
    &.active {
      background: #ffffff80;
      img {
        @include filter_FFF;
      }
    }
    &.disabled {
      cursor: not-allowed;
      @include filter_FFF_50;
    }

    &.pic {
      border-radius: 8px 0 0 8px;
    }

    &.video {
      border-radius: 0 8px 8px 0;
    }

    img {
      width: 24px;
      height: 24px;
      @include filter_FFF_20;
    }
  }
}

// .header {
//   display: flex;
//   align-items: center;
//   column-gap: 24px;
//   margin-bottom: px2rem(16);
//   outline: 1px dashed red;
// }

.esc {
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #ffffff1a;
  }
}

.btn-action {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #ffffff1a;
  }
  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.divider {
  width: 1px;
  height: 90%;
  background: #ffffff80;
}

.switch {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: #ffffff1a;
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.event-index {
  min-width: 54px;
  font-size: px2rem(18);
  line-height: 24px;
  color: #282942;
  background: #ffe99f;
  border-radius: 13px;
  margin-right: 24px;
  padding: 0 6px 2px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-history-modal {
  display: flex;
  height: calc(100% - 96px);
  background: #282942;
  padding-bottom: 20px;
  z-index: 1;
}

.history-screen-shot {
  position: absolute;
  left: 0px;
  top: 100px;
  width: 1200px;
  height: auto;
  background: #282942;
  padding: 20px;
  z-index: -1;

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 10px;
  }
}

.is-capturing {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.event-action {
  padding: 0 12px;
  margin-top: 45px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  .top,
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
  }

  .action {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.lock {
      background: #ffffff33;
      &:hover {
        background: #ffffff80;
      }
    }

    img {
      width: 22px;
      height: 22px;
      @include filter_FFF;
    }
    .engineer-disabled {
      @include filter_FFF_50;
    }
  }
}
</style>